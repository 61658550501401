.about__video {
	padding: 25px 0 60px;
	margin-bottom: 55px;
}

.about__video__block {
	padding-top: 20px;
}

.about__video__item {
	position: relative;
	z-index: 2;

	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: calc(100% - 5px);
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}

	a {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		text-align: center;
		transform: translate(-50%, -50%);
		display: block;
		color: #ffffff;
		font-size: 14px;
		text-transform: uppercase;

		&:hover .video__icon {
			background-color: $main-color;

			span {
				border-left-color: #fff;
			}
		}
	}
}

.about__video__icon {
	width: 89px;
	height: 88px;
	border: 1px solid $main-color;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 10px;

	span {
		border-top: 16px solid transparent;
		border-left: 24px solid $main-color;
		border-bottom: 16px solid transparent;
		padding-left: 5px;
		position: relative;
		right: -5px;
		box-sizing: border-box;
		display: block;
		width: 24px;
		height: 32px;
	}
}

.about__video__info {
	position: relative;
	margin-top: 60px;
	height: 100%;
	padding: 45px 30px 10px 10px;


	&:before {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: calc(100% + 100px);
		height: 100%;
		border: 4px solid $main-color;
	}
}

.about__video__name {
	color: $main-color;
	padding-bottom: 30px;
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
}

@include media-breakpoint-down(md) {
	.about__video__block {
		padding: 0;
	}

	.about__video__info {
		padding: 30px;
		height: auto;

		&::before {
			width: 100%;
		}
	}
}

