.slider {
	.slick-dots {
		position: absolute;
		left: 0;
		width: 100%;
		text-align: right;
		bottom: 60px;
		padding: 0 10%;
	}
}

.slider__block {
	position: relative;
	display: block;
}

.slider__image {
	width: 100%;
	height: 856px;
	background-position: 50%;
	background-size: cover;
	max-height: calc(100vh - 60px - 56px);
}

.slider__caption {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	align-items: center;
}

.slider__info {
	position: relative;
	padding: 0 0 0 80px;

	&:before {
		content: "";
		width: 380px;
		height: 380px;
		border: 6px solid $main-color;
		background-color: #ffffff;
		opacity: 0.4;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
		max-width: 100%;
	}
}

.slider__title,
.slider__description {
	position: relative;
	z-index: 2;
}

.slider__title {
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	color: $main-color;
	font-size: 56px;
	font-weight: 700;
	text-transform: uppercase;
}

.slider__description {
	margin-top: 15px;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	color: $secondary-color;
	font-size: 24px;
	font-weight: 600;
	padding-left: 24px;
	position: relative;
	min-height: 68px;
	display: flex;
	align-items: center;

	&:before {
		position: absolute;
		content: "";
		width: 2px;
		height: 68px;
		background-color: $main-color;
		transform: translate(0, -50%);
		left: 0;
		top: 50%;
	}
}

@include media-breakpoint-down(sm) {
	.slider__info {
		padding: 0 0 0 30px;

		&:before {
			width: 280px;
			height: 280px;
		}
	}

	.slider__title {
		font-size: 40px;
	}

	.slider__description {
		font-size: 17px;

		&:before {
			height: 45px;
		}
	}
}
