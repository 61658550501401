*, ::after, ::before {
	box-sizing: border-box;
}

* {
	min-width: 0;
}

a {
	text-decoration: none;
	text-decoration-skip-ink: none;
	outline: none !important;

	&:hover {
		text-decoration: none;
	}
}

input::-ms-clear {
	display: none;
}

.clearfix:before,
.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	overflow: hidden;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

input {
	outline: none;
	font-family: $font-family-base;
}

:focus {
	outline: none !important;
}

textarea {
	max-width: 100%;
	resize: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}

ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

svg {
	fill: $grey-color;

	&.active,
	&:hover {
		fill: $main-color;
	}

	&.youtube {
		&:hover,
		&.active {
			fill: #ff4342;
		}
	}

	&.instagram {
		&:hover,
		&.active {
			fill: #d932ac;
		}
	}

	&.facebook {
		&:hover,
		&.active {
			fill: #1e5a99;
		}
	}

	&.telegram {
		&:hover,
		&.active {
			fill: #32aae7;
		}
	}

	&.viber {
		&:hover,
		&.active {
			fill: #7d3daf;
		}
	}
}

.main {
	overflow: hidden;
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.mh-100 {
	min-height: 100%;
}

.mask {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 99px;
	background-color: #222427;
	opacity: 0.5;
	z-index: 4;
	display: none;
	cursor: pointer;
}

.mask.open {
	display: block;
}

.fixed {
	.mask {
		top: 60px;
	}
}

.btn {
	height: 42px;
	border: 2px solid $main-color;
	cursor: pointer;
	color: $main-color;
	font-size: 12px;
	font-weight: 700;
	line-height: 18px;
	padding: 0 80px 0 30px;
	text-transform: uppercase;
	position: relative;
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	max-width: 100%;
	vertical-align: middle;
	transition: background 1s, color 1s;
	background-position: 100%;
	background-size: 500%;
	background-image: linear-gradient(90deg, $main-color 50%, transparent 50%);

	&:hover {
		color: #fff;
		background-position: 0;
		background-color: $main-color;
	}

	input {
		width: 100%;
		height: 100%;
		border: 0;
		background-color: transparent;
		padding: 0;
		cursor: pointer;
		text-align: left;
		color: inherit;
		font-weight: inherit;
		text-transform: inherit;
	}

	svg {
		position: absolute;
		right: 30px;
		top: -50px;
		transform: translate(0, -50%);
		transition: top 1s;
		fill: #fff;
	}

	&:hover svg {
		top: 50%;
	}

	&:after {
		content: "";
		position: absolute;
		right: 30px;
		top: 50%;
		width: 30px;
		height: 2px;
		background-color: $main-color;
		transform: translate(0, -50%);
		transition: right 1s;
	}


	&:hover:after {
		color: #fff;
		right: -30px;
	}

	.ajax-loader {
		background: url("../svg/loader.svg");
		background-size: cover;
		width: 30px;
		height: 30px;
		top: calc(50% - 15px);
		right: 25px;
		position: absolute;
		opacity: 0;

		&.is-active {
			opacity: 1;

			& + svg {
				opacity: 0;
			}
		}
	}

	&.white {
		border-color: #fff;
		color: #fff;
		background-image: linear-gradient(90deg, #fff 50%, transparent 50%);

		&:after {
			background-color: #fff;
		}

		&:hover {
			background-color: #fff;
			color: $main-color;
		}

		svg {
			fill: $main-color;
		}
	}

	&.btn-mini {
		border: none;
		padding-left: 0;
		background-color: #fff;
		background-image: none;

		&:after {
			background-color: #fff;
		}

		svg {
			fill: $main-color;
		}

		&:hover {
			background-image: none;
			color: $main-color;

			svg {
				fill: $main-color;
			}
		}
	}

	&.btn-icon {
		svg {
			top: 50%;
			fill: $main-color;
			transition: all 0.5s;
		}

		&::after {
			display: none;
		}

		&:hover {
			svg {
				fill: #fff;
			}
		}
	}

	&.loader {
		svg {
			top: 50%;
			fill: $main-color;
			transition: fill 1s;
		}

		&:after {
			display: none;
		}


		&:hover {
			svg {
				fill: #fff;
			}
		}
	}
}

.screen-reader-response,
.wpcf7-response-output,
.wpcf7-not-valid-tip {
	display: none !important;
}


.animated-modal {
	overflow: hidden;
	transform: translateY(-50px);
	transition: all 0.7s;
}

.fancybox-slide--current .animated-modal {
	transform: translateY(0);
	opacity: 1;
}


.fancybox-slide--html .fancybox-close-small {
	color: #000;
	transition: all 1s;
	padding: 0;
	width: 20px;
	height: 20px;
	top: 5px;
	right: 5px;

	&:hover {
		transform: rotate(180deg);
	}
}

.title {
	color: $secondary-color;
	font-size: 30px;
	text-transform: uppercase;
	position: relative;
	margin: 50px 0;
	font-weight: 100;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: end;

	&:before {
		content: attr(data-shadow);
		color: #eef5f9;
		font-size: 60px;
		font-weight: 700;
		text-transform: uppercase;
		position: absolute;
		left: 0;
		bottom: -10px;
		overflow: hidden;
		display: inline-block;
		white-space: nowrap;
	}

	span {
		position: relative;
		z-index: 1;
	}

	span.bold {
		font-weight: 600;
	}

	&.red {
		color: $red-color;
	}
}

.js-particle {
	canvas {
		background-color: $main-color;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

.list {
	font-size: 14px;

	li {
		margin-bottom: 20px;
		position: relative;
		padding-left: 50px;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			width: 30px;
			height: 1px;
			background-color: $secondary-color;
			top: 50%;
			transform: translate(0, -50%);
		}
	}

	span {
		font-weight: 600;
	}
}

.nav-arrow {
	width: 52px;
	height: 52px;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background-color: $main-color;

		svg {
			fill: #fff;
		}
	}

	&.nav-next {
		transform: rotate(-180deg);
	}

	&.green {
		background-color: $main-color;
		border: 2px solid $main-color;

		svg {
			fill: #fff;
		}

		&:hover {
			background-color: #fff;

			svg {
				fill: $main-color;
			}
		}
	}
}

.main {
	padding-right: 100px;
}

.zoomImg {
	cursor: crosshair;
}

.description {
	font-size: 14px;
	line-height: 24px;

	ul {
		display: flex;
		flex-flow: column;
		padding-bottom: 20px;

		li {
			padding-left: 50px;
			padding-bottom: 5px;
			position: relative;

			&:before {
				position: absolute;
				left: 0;
				top: 12px;
				width: 30px;
				height: 1px;
				background-color: $secondary-color;
				content: "";
			}
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.alignright {
		float: right;
		padding: 15px 0 15px 15px;
	}

	.alignrleft {
		float: left;
		padding: 15px 15px 15px 0;
	}
}

.breadcrumbs {
	border-bottom: 2px solid #eceff1;

	ul {
		font-size: 0;
		white-space: nowrap;
		overflow: auto;
		padding: 15px 0 25px;

		li {
			font-size: 14px;
			display: inline-block;
			color: $main-color;
			position: relative;
			white-space: nowrap;
			font-weight: 700;
			padding-right: 30px;

			a {
				color: $grey-dark-color;
				font-weight: 400;
			}

			&::before {
				width: 6px;
				height: 6px;
				background-color: $main-color;
				display: inline-block;
				content: "";
				position: absolute;
				right: 12px;
				top: 6px;
			}


			&:last-child {
				padding-right: 0;

				&::before {
					display: none;
				}
			}
		}
	}
}

.pagination {
	border-top: 1px solid #f5f5f5;
	padding-top: 40px;

	.page-numbers {
		display: flex;
		justify-content: flex-end;

		li span,
		li a {
			min-width: 42px;
			margin-left: 10px;
			min-height: 42px;
			border: 2px solid #f5f5f5;
			background-color: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			&:hover, &.current {
				background-color: $main-color;
				color: #fff;
				border-color: $main-color;

				svg {
					fill: #fff;
				}
			}

			&.prev {
				svg {
					transform: rotate(-180deg);
				}
			}
		}
	}
}

hr {
	border: none;
	font-size: 0;
	border-top: 2px solid #b0bec5;
	margin: 35px 0;
}

@include media-breakpoint-down(sm) {
	.breadcrumbs ul {
		padding: 15px 0 15px;
	}

	.mask {
		top: 60px;
	}

	.main {
		padding-right: 0;
	}

	.title {
		align-items: center;
		justify-content: center;
		flex-flow: column;
		text-align: center;

		.btn {
			margin-top: 15px;
		}

		&::before {
			display: none;
		}
	}
}
