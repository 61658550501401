.header-top__title {
	color: $main-color;
	font-size: 12px;
	font-weight: 300;
	text-transform: uppercase;
	height: 39px;
	display: flex;
	align-items: center;
}

.header-bottom__logo {
	position: absolute;
	top: -5px;
	height: 70px;
	transition: all 0.5s;
	z-index: 6;
}

.header-bottom {
	background-color: $grey-light-color;
	position: relative;
	border-top: 1px solid #eceff1;
	border-bottom: 1px solid #eceff1;
	height: 60px;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.header-top {
	position: relative;
	height: 39px;
}

.header-top__account {
	position: absolute;
	right: 0;
	top: 0;
}

.account-link {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $main-color;
	color: #ffffff;
	font-size: 12px;
	font-weight: 600;
	width: 200px;
	height: 39px;
	text-transform: uppercase;
	border: 2px solid $main-color;
	transition: background-color 0.5s, color 0.5s;

	svg {
		fill: #ffffff;
		margin-right: 20px;
		transition: fill 0.5s;
	}

	&:hover {
		background-color: transparent;
		color: $main-color;

		svg {
			fill: $main-color;
		}
	}
}

.header-bottom__menu {
	display: flex;
	align-items: center;
	height: 100%;
	padding-left: 112px;

	li {
		display: inline-block;
		padding-right: 50px;
		padding-top: 5px;
		position: relative;

		&:last-child {
			padding-right: 0;
		}

		&.active a, a:hover {
			position: relative;

			&::before {
				content: "";
				width: 85%;
				position: absolute;
				left: 50%;
				bottom: -5px;
				height: 2px;
				background-color: $main-color;
				transform: translate(-50%, 0);
			}
		}
	}

	a {
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
	}
}

.header-bottom__search {
	position: absolute;
	right: 0;
	top: 10px;
	border: 1px solid #eceff1;
	background-color: #ffffff;
	width: 15%;
	transition: width 0.5s;
}

.header-bottom__form {
	display: flex;
	justify-content: space-between;

	input {
		border: 0;
		height: 38px;
		font-size: 12px;
		font-weight: 600;
		padding-left: 20px;
		text-transform: uppercase;
		width: 100%;

		&::placeholder {
			color: #8b8b8b;
			opacity: 1;
		}
	}

	button {
		background-color: #fff;
		border: 0;
		padding: 0 20px;
		cursor: pointer;
		flex-shrink: 0;
	}
}

.header-menu__account {
	margin: 20px 10px 50px;

	.account-link {
		width: 100%;
	}
}

.header-menu__search {
	border: 1px solid #eceff1;
	margin: 20px 10px 0;
}

.header-bottom__mmenu {
	display: block;
	width: 30px;
	height: 19px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	position: relative;
	z-index: 2;
	left: 15px;

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background-color: $main-color;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: .25s ease-in-out;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: 8px;
		}

		&:nth-child(3) {
			top: 16px;
		}
	}

	&.open span {
		&:nth-child(1) {
			top: 8px;
			transform: rotate(135deg);
		}

		&:nth-child(2) {
			opacity: 0;
			left: -60px;
		}

		&:nth-child(3) {
			top: 8px;
			transform: rotate(-135deg);
		}
	}
}

.fixed {
	padding-top: 60px;

	.header-bottom__logo {
		transform: scale(0.6);
	}

	.header-bottom {
		position: fixed;
		top: 0;
		left: 0;
		width: calc(100% - 100px);
		z-index: 3;
	}
}

.header-menu {
	height: 100%;
	max-width: 300px;
	width: 100%;
	max-height: 100%;
	overflow: auto;
	position: fixed;
	left: -300px;
	z-index: 11;
	background-color: $grey-light-color;
	transition: left 0.3s;

	&.open {
		left: 0;
	}
}

.header-menu__menu li {
	border-bottom: 1px solid #e5e5e5;

	a {
		display: block;
		color: $secondary-color;
		font-weight: 600;
		transition: margin ease .5s;
		padding: 10px 15px;
	}

	&:hover a {
		margin-left: 20px;
	}
}

.header-menu__social {
	ul {
		display: flex;
		justify-content: space-around;
	}
}


@include media-breakpoint-down(lg) {

	.header-bottom__form {
		justify-content: center;
	}

	.header-bottom__search {
		width: 42px;

		input {
			padding: 0;
			width: 0;
		}

		button {
			padding: 0;
		}

		&:not(.active):hover {
			background-color: $main-color;

			svg {
				fill: #fff;
			}

			button {
				background-color: $main-color;
			}
		}

		&.active {
			width: 250px;

			.header-bottom__form {
				justify-content: space-between;
			}

			input {
				padding-left: 20px;
				width: 100%;
			}

			button {
				padding: 0 20px;
			}
		}
	}

	.header-bottom__menu {
		li {
			padding-right: 15px;
		}

		a {
			font-size: 13px;
		}
	}
}

@include media-breakpoint-down(md) {
	.header-menu {
		top: 99px;
	}
	.header-bottom__logo {
		text-align: center;
		left: 50%;
		transform: translate(-50%, 0);
	}

	.fixed {
		.header-bottom__logo {
			transform: translate(-50%, 0) scale(0.6);
		}

		.header-menu {
			top: 60px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.header-menu {
		top: 60px;
	}

	.header-bottom__logo {
		transform: translate(-50%, 0) scale(0.6);
	}

	.header-bottom {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 2;
	}

	body {
		padding-top: 60px;
	}
}
