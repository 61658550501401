$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1400px
) !default;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1350px
) !default;

$main-color: #72bf44;
$secondary-color: #454545;

$grey-color: #b0bec5;
$grey-light-color: #fafafa;
$grey-dark-color: #8b8b8b;
$red-color: #ff6666;
$green-color: #a6d1b2;

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

$link-color: $secondary-color  !default;
$link-hover-color: $main-color !default;

$body-color: $secondary-color !default;

$font-family-base: 'Proxima Nova', sans-serif !default;
$font-size-base:  0.9375rem !default;
$font-weight-base: 400 !default;
$line-height-base: 1.25 !default;

$body-bg: #fff !default;

