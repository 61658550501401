.news {
	position: relative;
	padding-top: 20px;
	background-color: $grey-light-color;
	margin-top: 130px;
	margin-bottom: 30px;
}

.news__slider {
	padding: 0 30px;
}

.news__item {
	text-align: right;
	position: relative;
	padding: 0 15px;
}

.news__image {
	position: absolute;
	right: 0;
	padding: 0 15px;
	z-index: -1;
	top: 50%;
	transform: translate(0, -50%);

	img {
		margin-left: auto;
	}

	&:before {
		content: "";
		position: absolute;
		left: 15px;
		top: 0;
		width: calc(100% - 30px);
		height: 100%;
		background-color: rgba(255, 255, 255, 0.7);
	}
}

.news__block {
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding: 80px 30% 80px 0;
}

.news__caption {
	margin-top: auto;
}

.news__title {
	padding-bottom: 30px;

	a {
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.news__description {
	font-size: 14px;
	padding-bottom: 55px;
}

.popular__item {
	padding: 0 0 0 15px;
}

.news__slider {
	width: 100%;
}

@include media-breakpoint-down(lg) {
	.news__block {
		padding: 80px 15% 80px 0;
	}
}

@include media-breakpoint-down(sm) {
	.news__block {
		padding: 30px 15px;
	}
}

@include media-breakpoint-down(xs) {
	.news__block {
		padding: 30px 0;
	}

	.news__item {
		text-align: center;
	}
}
