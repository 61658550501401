.about__about {
	padding: 60px 0;
}

.about__about__full {
	position: relative;
	overflow: hidden;
	padding: 50px 0;
}

.about__about__title {
	h1 {
		margin: 0;
		color: $main-color;
		font-size: 30px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 0 0 25px;
	}
}

.about__about__block {
	background-color: rgba(255, 255, 255, 0.9);
	padding: 35px 0 35px 110px;
}

.about__about__image {
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}

@include media-breakpoint-down(lg) {
	.about__about__block {
		padding: 35px 35px 0;
	}
}

@include media-breakpoint-down(sm) {
	.about__about__full {
		padding: 0;
	}

	.about__about__block {
		padding: 15px 30px;
	}
}

