.about__top {
	position: relative;
	padding-bottom: 15px;
}

.about__top__image {
	width: 100%;
	height: 400px;
	background-size: cover;
	background-position: 50%;
	max-height: calc(100vh - 60px - 56px);
}

.about__top__caption {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	top: 0;
	display: flex;
	align-items: center;
	z-index: 1;
}

.about__top__title {
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
	color: $secondary-color;
	font-size: 56px;
	font-weight: 700;
	text-transform: uppercase;
	padding-left: 81px;

	&:before {
		content: "";
		width: 204px;
		height: 204px;
		border: 6px solid $main-color;
		background-color: #ffffff;
		opacity: 0.4;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translate(0, -50%);
		max-width: 100%;
		z-index: -1;
	}
}

@include media-breakpoint-down(sm) {
	.about__top__title {
		font-size: 40px;
		padding-left: 30px;

		&:before {
			left: 15px;
			width: 150px;
			height: 150px;
		}
	}
}
