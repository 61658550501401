.popular {
	position: relative;
	margin-top: 100px;
	margin-bottom: 30px;
}

.popular__right {
	position: absolute;
	width: 100%;
	height: calc(100% - 5px);
	right: 0;
	padding: 0;
	top: 5px;
	z-index: -1;
}

.popular__slider {
	background-color: #ffffff;
}

.popular__item {
	padding: 0 0 0 15px;
}

.popular__block {
	display: block;
	border: 2px solid $grey-light-color;
	padding: 35px 30px 72px;

	&:hover {
		border-color: $main-color;
		padding-bottom: 30px;

		.popular__caption {
			display: block;
		}
	}
}

.popular__name {
	padding-bottom: 15px;

	span, a {
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;
		height: 35px;
		overflow: hidden;
		display: block;
	}
}

.popular__description {
	margin-bottom: 15px;
	color: $grey-dark-color;
	font-size: 14px;
	font-weight: 400;
	height: 54px;
	overflow: hidden;
}

.popular__price {
	margin-bottom: 35px;
	font-weight: 700;
	font-size: 18px;
	color: $main-color;
	display: flex;
	align-items: baseline;

	span {
		font-size: 14px;
		font-weight: 400;
		padding-left: 5px;
	}

	.old{
		color: #8b8b8b;
		font-size: 16px;
		text-decoration: line-through;
		padding-right: 10px;
	}

	.new{
		color: $red-color;
	}
}

.popular__image {
	padding: 20px 0 50px;
	text-align: center;
	display: block;

	img {
		margin: 0 auto;
	}
}

.popular__caption {
	display: none;

	.btn {
		display: flex;
	}
}

.popular__particle {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.popular__nav {
	position: absolute;
	right: 15px;
	top: calc(50% - 40px);
	transform: translate(0, -50%);

	.nav-arrow {
		background-color: transparent;
		border: 1px solid #fff;
		margin: 10px 0;

		svg {
			fill: #fff;
		}

		&:hover {
			background-color: #fff;

			svg {
				fill: $main-color;
			}
		}
	}
}

.popular__btn {
	position: absolute;
	right: 15px;
	bottom: 80px;
}

.popular__counter {
	font-size: 18px;
	color: #fff;
	position: absolute;
	left: 85px;
	top: calc(50% - 40px);
	transform: translate(0, -50%);

	span {
		font-size: 36px;
		font-weight: 700;
	}
}
@include media-breakpoint-down(lg) {
	.popular__counter{
		left: 55px;
	}
}
@include media-breakpoint-down(md) {
	.popular__block {
		padding: 35px 30px 0;
		border-color: transparent;

		.popular__caption {
			display: block;
		}

		&:hover{
			padding-bottom: 0;
			border-color: transparent;
		}
	}

	.popular__counter{
		left: 45px;
	}
}
