.trust{
	margin-top: 80px;
	margin-bottom: 40px;
	padding: 60px 0 95px;
	background-image: url("/wp-content/themes/wolf-studio/assets/images/trust_bg.jpg");
}

.trust__item{
	position: relative;
	padding: 20px;
	margin: 20px 0;

	&:before, &:after{
		width: 30px;
		height: 30px;
		position: absolute;
		left: 0;
		border-left: 2px solid $main-color;
		content: "";
	}

	&:before{
		border-top: 2px solid $main-color;
		top: 0;
	}

	&:after{
		border-bottom: 2px solid $main-color;
		bottom: 0;
	}
}

.trust__title{
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
	padding-bottom: 15px;

	span{
		color: $main-color;
	}
}

.trust__description{
	font-size: 14px;
	line-height: 24px;
}

.trust__footer{
	font-size: 16px;
	font-weight: 700;
	margin-top: 40px;
}
