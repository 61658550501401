.special {
	padding-top: 60px;
	padding-bottom: 30px;

	.owl-dots {
		padding: 0;
		right: 0;
		bottom: 50%;
		width: 20px;
		transform: translate(0, -50%);
	}
}

.special__block {
	padding: 0 112px;
}

.special__image {
	position: relative;
}

.special__footer{
	color: $grey-dark-color;
	font-size: 14px;
	padding-top: 20px;
}

.special__title {
	color: $red-color;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 30px;
	position: relative;

	&:before {
		content: attr(data-index);
		position: absolute;
		left: -75px;
		top: 0;
		color: $main-color;
		font-size: 18px;
		font-weight: 400;
	}

	&:after {
		content: "";
		position: absolute;
		left: -40px;
		top: 10px;
		width: 24px;
		height: 1px;
		background-color: $main-color;
	}
}

.special__description {
	color: $grey-dark-color;
	font-size: 14px;
}

.special__caption {
	margin-top: 40px;
	padding-top: 40px;
	border-top: 1px dotted $grey-color;
	display: flex;
	justify-content: space-between;
}

.special__old {
	color: $grey-dark-color;
	font-size: 16px;
	font-weight: 700;
	text-decoration: line-through;

	span {
		font-weight: 400;
		font-size: 14px;
		padding-left: 5px;
	}
}

.special__new {
	color: $red-color;
	font-size: 24px;
	font-weight: 700;

	span {
		font-size: 18px;
		font-weight: 400;
		padding-left: 5px;
	}
}

.special__nav {
	display: flex;
	position: absolute;
	right: 0;
	bottom: 0;
}

.special__slider {
	.slick-dots {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translate(0, -50%);
		display: flex;
		flex-flow: column;
	}
}

.special__item{
	padding: 0 15px;
}

@include media-breakpoint-down(lg) {
	.special__block {
		margin-top: 50px;
		padding: 0 75px;
	}
}
@include media-breakpoint-down(md) {
	.special__block {
		padding: 0;
		margin-top: 50px;
	}

	.special__title::before,
	.special__title::after {
		display: none;
	}
}
