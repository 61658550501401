.contacts__map {
	width: 100%;
	height: auto;
}

.map__active {
	.map__item {
		fill: $green-color;
	}

	&.active,
	&:hover {
		cursor: pointer;

		.map__item {
			fill: $main-color;
		}
	}
}

.map__no-active {
	.map__item {
		fill: #D5D5D5;
	}
}

.map__dots {
	fill: #FFFFFF;
}

.map__name {
	fill: #474747;
	font-size: 7px;
	font-weight: 700;
	text-transform: uppercase;
}
