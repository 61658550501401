.about {
	padding-top: 30px;
	padding-bottom: 80px;
}

.about__title {
	color: $main-color;
	font-size: 30px;
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 25px;
}

.about__description {
	columns: 2;
}


.about__block {
	background-color: rgba(255, 255, 255, 0.9);
	padding: 75px 0 50px 110px;
}

.about__full {
	position: relative;
	overflow: hidden;
	padding: 50px 0;
}

.about__image {
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
}

@include media-breakpoint-down(lg) {
	.about__block {
		padding: 40px;
	}
}

@include media-breakpoint-down(sm) {
	.about__description{
		columns: 1;
	}

	.about__full{
		padding: 0;
	}

	.about__block {
		padding: 15px 30px;
	}
}
