.partners {
	margin-top: 110px;
	overflow: hidden;
}

.partners__block {
	position: relative;
	padding: 60px 115px 90px 0;

	&::after {
		background-color: $grey-light-color;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 10000px;
		z-index: -1;
		content: "";
	}
}

.partners__item {
	padding: 0 15px;
}

.partners__slider {
	width: 100%;
}

.partners__image {
	height: 100px;
	background-color: #fff;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #fff;
	cursor: pointer;

	&.active,
	&:hover {
		border-color: $main-color;
	}
}

.partners__header {
	display: flex;
	justify-content: space-between;
}

.partners__nav {
	display: flex;
	align-items: center;

	.nav-prev {
		margin-left: 10px;
	}
}

.partners__body {
	display: none;

	&.show {
		display: block;
	}
}

.partners__name {
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 25px;
	position: relative;

	&:before {
		width: 80px;
		height: 2px;
		background-color: #72bf44;
		content: "";
		position: absolute;
		right: calc(100% + 20px);
		top: 15px;
	}
}

.partners__right {
	padding-top: 190px;
	padding-left: 30px;
}

@include media-breakpoint-down(md) {
	.partners__block {
		padding: 15px 15px 15px 0;
	}

	.partners__right {
		padding: 30px 0;
	}

	.partners__name::before {
		display: none;
	}
}
