.seo {
	padding: 90px 0;
}

.seo__title {
	padding-bottom: 40px;

	h1 {
		color: $secondary-color;
		font-size: 30px;
		font-weight: 300;
		text-transform: uppercase;
		margin: 0;
		padding: 0;
	}
}

.seo__scrollbar {
	max-height: 353px;
}

.seo__video {
	position: relative;
	max-width: 470px;
	margin: 10px auto;

	video {
		width: 100%;

		&:hover {
			+ .seo__video-control {
				opacity: 1;
			}
		}
	}
}

.seo__video-control {
	width: 90px;
	height: 90px;
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 100px;
	transition: opacity 0.3s;

	&.playing {
		opacity: 0;
	}

	span {
		box-sizing: border-box;
		display: block;
		width: 24px;
		height: 32px;
	}

	&.paused span {
		border-top: 16px solid transparent;
		border-left: 24px solid #fff;
		border-bottom: 16px solid transparent;
		padding-left: 5px;
		position: relative;
		right: -5px;
	}

	&.playing span {
		border-right: 8px solid #fff;
		border-left: 8px solid #fff;
	}

	&:hover {
		opacity: 1;

		span {
			border-left-color: $main-color;
			border-right-color: $main-color;
		}
	}
}

@include media-breakpoint-down(md) {
	.seo__video {
		margin-top: 50px;
	}
}
