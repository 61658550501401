.why{
	padding-top: 1px;
	padding-bottom: 60px;
}

.why__block{
	background-color: $grey-light-color;
	position: relative;
	overflow: hidden;
	padding: 75px 0 0;

	&:before, &:after{
		width: 200px;
		height: 200px;
		position: absolute;
		content: "";
	}

	&:before{
		border-left: 4px solid $main-color;
		border-top: 4px solid $main-color;
		top: 0;
		left: 0;
	}

	&:after{
		border-right: 4px solid $main-color;
		border-bottom: 4px solid $main-color;
		bottom: 0;
		right: 0;
	}
}

.why__icon{
	text-align: center;
	margin-bottom: 25px;
}

.why__name{
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
	margin-bottom: 75px;
	padding: 0 30px;
}
