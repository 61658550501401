.achievements {
	padding-top: 35px;
	padding-bottom: 80px;
}

.achievements__block {
	border: 1px solid #e8e8e8;
	border-bottom: 0;
	border-left: 0;
}

.achievements__item {
	height: 100%;
	border-left: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	padding: 40px;
	display: flex;
	flex-flow: column;
}

.achievements__name {
	color: $secondary-color;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	padding-top: 20px;
	padding-bottom: 15px;
}

.achievements__name-big {
	font-size: 24px;
}

.achievements__caption {
	margin-top: auto;
}

.achievements__description {
	color: $secondary-color;
	font-size: 14px;
	line-height: 24px;
	padding-bottom: 30px;
}

.achievements__footer{
	text-align: center;
	padding-top: 45px;
}

.achievements__slide{
	padding: 0 15px;
}

.achievements__nav {
	display: flex;
	align-items: center;

	.nav-prev {
		margin-left: 10px;
	}

	.nav-arrow{
		border: 1px solid #e8e8e8;
	}
}
