.success {
	margin: 60px 0;
	position: relative;
	padding: 160px 0;
}

.success__particle {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.success__number {
	text-align: center;
	color: #ffffff;
	font-size: 48px;
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 20px;
}

.success__description {
	font-size: 16px;
	font-weight: 300;
	text-align: center;
	color: #ffffff;

	span {
		display: block;
		font-weight: 700;
	}
}

.success__title {
	font-size: 30px;
	font-weight: 300;
	color: #ffffff;
	text-transform: uppercase;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	position: relative;

	span {
		font-size: 48px;
		font-weight: 700;
	}

	&:before {
		position: absolute;
		width: 2px;
		height: 126px;
		background-color: rgba(255, 255, 255, 0.5);
		right: 44px;
		top: 50%;
		transform: translate(0, -50%);
		content: "";
	}
}

.success__block {
	position: relative;
	text-transform: uppercase;
}

.success__separator {
	background-color: rgba(255, 255, 255, 0.5);
	position: absolute;
	height: 34px;
	width: 2px;
	right: -15px;
	transform: rotate(25deg);
	top: 10px;
}

@include media-breakpoint-down(lg) {
	.success {
		padding: 60px 0;
	}

	.success__title {
		text-align: center;
		padding-bottom: 50px;

		&::before {
			position: absolute;
			width: 126px;
			height: 2px;
			top: calc(100% - 25px);
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
}

@include media-breakpoint-down(md) {
	.success__number{
		padding-bottom: 0;
	}

	.success__separator {
		display: none;
	}

	.success__block {
		padding-bottom: 25px;
	}
}
