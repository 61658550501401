.player {
	margin-bottom: -40px;
	background-color: $grey-light-color;
	margin-top: 60px;
	padding: 60px 0;
}

.player__title {
	font-weight: 100;
	font-size: 30px;
	line-height: 36px;
	text-transform: uppercase;
	text-align: right;

	span {
		display: block;
		font-weight: 600;
	}
}

.player__block {
	height: 92px;
	border: 1px solid #e8e8e8;
	background-color: #ffffff;
	padding: 30px 40px;
	display: flex;
	align-items: center;
}

.audioplayer {
	display: flex;
	align-items: center;
	width: 100%;
}

.audioplayer-playpause {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	cursor: pointer;
	transition: all .2s ease-in-out;
}

.audioplayer .audioplayer-playpause {
	border: 2px solid $main-color;
}

.audioplayer .audioplayer-playpause:hover {
	background: $main-color;
}

.audioplayer:not(.audioplayer-playing) .audioplayer-playpause {
	&:hover a {
		border-left: 8px solid #fff;
	}

	a {
		content: '';
		justify-content: center;
		width: 0;
		height: 0;
		margin-left: 2px;
		border-top: 6px solid transparent;
		border-right: none;
		border-bottom: 6px solid transparent;
		border-left: 8px solid $main-color;
	}
}

.audioplayer-playing .audioplayer-playpause a {
	content: '';
	display: flex;
	justify-content: space-between;
	width: 8px;
	height: 10px;
}

.audioplayer-playing .audioplayer-playpause {
	&:hover {
		a::before,
		a::after {
			background-color: #fff;
		}
	}

	a::before,
	a::after {
		content: '';
		width: 2px;
		height: 10px;
		background-color: $main-color;
	}
}

.audioplayer-time {
	display: flex;
	width: 40px;
	justify-content: center;
	font-size: 12px;
	color: $secondary-color;
}

.audioplayer-time-current {
	margin-left: 24px;
}

.audioplayer-time-duration {
	margin-right: 24px;
}

.audioplayer-bar {
	position: relative;
	display: flex;
	margin: 0 12px;
	height: 16px;
	flex-basis: 0;
	flex-grow: 1;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		top: 5px;
		width: 100%;
		height: 6px;
		background-color: $grey-light-color;
	}
}

.audioplayer-bar > div {
	position: absolute;
	left: 0;
	top: 5px;
}

.audioplayer-bar-loaded {
	z-index: 1;
	height: 6px;
	background: $grey-color;
}

.audioplayer-bar-played {
	flex-direction: row-reverse;
	z-index: 2;
	height: 6px;
	background-color: $main-color;
}

.audioplayer-bar-played::after {
	position: absolute;
	content: '';
	top: -5px;
	right: -1px;
	margin-right: -5px;
	width: 15px;
	height: 15px;
	background-color: $main-color;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.17);
	border-radius: 15px;
}

.audioplayer-volume {
	display: flex;
	align-items: center;
}

.audioplayer-volume-button {
	display: flex;
	align-items: center;
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.audioplayer-volume-button a {
	display: flex;
	width: 6px;
	height: 8px;
	background-color: #242424;
	border-radius: 2px 0 0 2px;
	position: relative;
}

.audioplayer-volume-button a:before, .audioplayer-volume-button a:after {
	content: '';
	position: absolute;
}

.audioplayer-volume-button a:before {
	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-right: 9px solid #242424;
	border-bottom: 8px solid transparent;
	border-left: none;
	top: -4px;
}

.audioplayer:not(.audioplayer-mute) .audioplayer-volume-button a:after {
	left: 11px;
	top: -1px;
	width: 10px;
	height: 10px;
	border: 6px double #242424;
	border-width: 6px 6px 0 0;
	border-radius: 0 12px 0 0;
	transform: rotate(45deg);
}

.audioplayer-mute .audioplayer-volume-button a {
	background-color: #FD4F1A;
}

.audioplayer-mute .audioplayer-volume-button a:before {
	border-right: 9px solid #FD4F1A;
}

.audioplayer-volume-adjust {
	display: flex;
	align-items: center;
	margin-left: 8px;
}

.audioplayer-volume-adjust > div {
	position: relative;
	display: flex;
	width: 110px;
	height: 6px;
	cursor: pointer;
	background-color: $grey-color;

	::after {
		position: absolute;
		content: '';
		top: -5px;
		right: -1px;
		margin-right: -5px;
		width: 15px;
		height: 15px;
		background-color: $main-color;
		box-shadow: 0 0 7px rgba(0, 0, 0, 0.17);
		border-radius: 15px;
	}
}

.audioplayer-volume-adjust div div {
	position: absolute;
	top: 0;
	left: 0;
	height: 6px;
	background-color: $main-color;
}

.player__download {
	padding-left: 85px;

	svg {
		fill: $main-color;
	}
}


@include media-breakpoint-down(lg) {
	.player__download {
		padding-left: 0;
	}

	.player__title {
		text-align: center;
		padding-bottom: 25px;
	}

	.player__block {
		padding: 30px 15px;
	}

	.audioplayer-volume {
		display: none;
	}
}
