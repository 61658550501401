.footer {
	margin-top: 40px;
	position: relative;
}

.footer__particle {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.footer__bottom {
	color: #cde2c1;
	font-size: 12px;
	border-top: 1px solid #81c784;
	padding: 20px 0;
}

.footer__copy {
	padding: 10px 0;

	a {
		text-decoration: underline;
		text-transform: uppercase;
		color: #fff;

		&:hover {
			text-decoration: none;
		}
	}
}

.footer__slogan {
	color: #ffffff;
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
}

.footer__wolfstudio {
	a {
		color: #cde2c1;
		text-decoration: none;
		font-weight: 300;

		&:hover {
			color: #fff;
		}
	}

	span {
		padding-left: 5px;
		text-decoration: underline;
	}
}

.sidebar {
	width: 100px;
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 5;
	display: flex;
	flex-flow: column;
	text-align: center;

	&:after {
		position: absolute;
		left: 0;
		width: 100%;
		top: 0;
		height: 100%;
		background-color: $grey-light-color;
		content: "";
		z-index: 1;
	}
}

.sidebar__language {
	text-transform: uppercase;
	text-align: center;
	padding-top: 12px;
	font-weight: 600;
	transition: padding 0.5s;
	position: relative;
	z-index: 3;
}

.dropdown__title {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	&.open{
		svg{
			transform: rotate(360deg);
		}
	}

	svg {
		margin-left: 10px;
		transform: rotate(180deg);
		transition: transform 0.5s;
	}

	&:hover,
	&.open {
		color: $main-color;

		svg {
			fill: $main-color;
		}
	}
}

.dropdown {
	position: relative;
}

.dropdown__content {
	display: none;
	position: absolute;
	left: 25px;
	top: 100%;
	padding-top: 5px;
}


.sidebar__contact {
	margin-top: auto;

	a {
		position: relative;
		display: block;
		padding: 55px 0;
		cursor: pointer;
		transition: background-color 0.25s;
		z-index: 2;

		&:after {
			position: absolute;
			top: 0;
			left: calc(50% - 20px);
			width: 40px;
			height: 1px;
			content: "";
			background-color: #dddddd;
			transition: background-color 0.25s;
		}

		&:hover, &.active {
			background-color: $main-color;

			svg {
				fill: #fff;
			}

			&:after {
				background-color: $main-color;
			}
		}
	}
}

.sidebar__call {
	position: absolute;
	overflow: hidden;
	width: 962px;
	box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	bottom: 0;
	right: -962px;
	transition: right 0.5s;
	padding: 40px 40px 20px;
	text-align: left;
	z-index: 1;
}

.js-sidebarCall.active + .sidebar__call {
	right: 100%;
}

.sidebar__social {
	margin-top: 50%;
	position: relative;
	z-index: 3;

	li {
		padding: 30px 0;
	}
}

.fixed {
	.sidebar__language {
		padding-top: 23px;
	}
}

.call__title {
	color: $secondary-color;
	font-weight: 700;
	text-transform: uppercase;
}

.call__description {
	color: #8b8b8b;
	padding-top: 5px;
	margin-bottom: 30px;
}

.call__input,
.call__btn {
	margin-bottom: 20px;
}

.call__input {
	label {
		color: $main-color;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	input {
		height: 42px;
		border: 1px solid #d1d1d1;
		font-size: 14px;
		padding-left: 20px;
		width: 100%;

		&.wpcf7-not-valid {
			border: 1px solid #f34840;
		}
	}
}

.footer__item {
	color: #ffffff;
	font-size: 14px;
	text-transform: uppercase;
	text-align: right;
	padding: 15px 0;

	a {
		color: #ffffff;

		&:hover {
			text-decoration: underline;
		}
	}

	&.bold {
		font-weight: 700;
	}
}

.footer__block {
	padding: 35px 0;
}

.footer__top {
	width: 52px;
	height: 52px;
	border: 1px solid #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	svg {
		fill: #fff;
	}

	&:hover {
		background-color: #fff;

		svg {
			fill: $main-color;
		}
	}
}

.header-bottom__language {
	position: relative;
	right: 15px;
	z-index: 2;
	font-weight: 600;
	text-transform: uppercase;
}

.modal-success {
	padding: 30px;
	width: 428px;
	display: none;
}

.modal-success__block {
	border: 2px solid $main-color;
	padding: 60px 30px;
	display: flex;
	flex-flow: column;
	align-items: center;
	text-align: center;
}

.modal-success__title {
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	padding-top: 35px;
	padding-bottom: 10px;
}

.modal-success__open {
	color: $grey-dark-color;
	font-size: 12px;
	font-weight: 400;
	padding-top: 15px;
}

.modal-success__description {
	color: $grey-dark-color;
	font-size: 14px;
	font-weight: 400;
}

@include media-breakpoint-down(lg) {
	.sidebar__call {
		width: 850px;
	}

	.footer__bottom {
		text-align: center;
		padding: 10px 0;
	}
}

@include media-breakpoint-down(md) {
	.sidebar__call {
		width: 650px;
	}

	.footer__block,
	.footer__item {
		text-align: center;
	}

	.footer__top {
		display: inline-flex;
		margin: 30px 0;
	}
}

@include media-breakpoint-down(sm) {
	.footer__slogan {
		padding-bottom: 15px;
	}

	.dropdown__content {
		left: 0;
	}
}
