.contacts__info__name {
	font-size: 18px;
	font-weight: 600;
	padding: 35px 0;
	border-top: 2px solid $grey-color;
	border-bottom: 2px solid $grey-color;
}

.contacts__info__item {
	font-size: 13px;
	font-weight: 400;
	line-height: 18px;
	display: flex;
	align-items: center;
	margin: 30px 0;
}

.contacts__info__rounded,
.contacts__info__square {
	width: 18px;
	height: 18px;
	background-color: $green-color;
	margin-right: 10px;
	flex-shrink: 0;


	&.active {
		background-color: $main-color;
	}
}

.contacts__info__rounded {
	border-radius: 18px;
}

.contacts__info__square {
	&.active {
		background-color: #d5d5d5;
	}
}

.contact__block{
	padding-bottom: 80px;
}
