@font-face{
	font-family: 'Proxima Nova';
	src: url('../font/Proxima Nova Thin.otf');
	font-weight: 100;
	font-style: normal;
}

@font-face{
	font-family: 'Proxima Nova';
	src: url('../font/Proxima Nova Light.otf');
	font-weight: 300;
	font-style: normal;
}

@font-face{
	font-family: 'Proxima Nova';
	src: url('../font/Proxima Nova Regular.otf');
	font-weight: 400;
	font-style: normal;
}

@font-face{
	font-family: 'Proxima Nova';
	src: url('../font/Proxima Nova Semibold.otf');
	font-weight: 600;
	font-style: normal;
}

@font-face{
	font-family: 'Proxima Nova';
	src: url('../font/Proxima Nova Bold.otf');
	font-weight: 700;
	font-style: normal;
}
