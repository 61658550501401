.founders {
	padding-top: 1px;
	padding-bottom: 120px;
}

.founders__block {
	position: relative;
	padding-top: 20px;
}

.founders__info {
	height: 100%;
	width: 100%;
	padding-left: 20px;
	padding-top: 55px;
	padding-right: 44px;
	position: relative;
	margin-top: 60px;

	&:before {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: calc(100% + 100px);
		height: 100%;
		border: 4px solid $main-color;
	}
}

.founders__image {
	position: relative;
	z-index: 1;
}

.founders__name {
	color: $secondary-color;
	font-size: 24px;
	font-weight: 700;
	text-transform: uppercase;
	padding-bottom: 5px;
}

.founders__sub-name {
	padding-top: 5px;
	padding-bottom: 15px;
	color: $main-color;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
}

.founders__description {
	padding-top: 15px;
}

@include media-breakpoint-down(md) {
	.founders__block {
		padding: 0;
	}

	.founders__info {
		padding: 30px;
		height: auto;

		&::before {
			width: 100%;
		}
	}
}
